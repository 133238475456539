import React, {useEffect, useLayoutEffect, useState} from 'react'
import DatePicker, {registerLocale} from "react-datepicker";
import './SensorChart.css';
import "react-datepicker/dist/react-datepicker.css";
import de from 'date-fns/locale/de';
import moment from 'moment';


import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);

registerLocale('de', de)

let initialStartDate = new Date();
initialStartDate = initialStartDate.setHours(0,0,0,0);

let initialEndDate = new Date();
initialEndDate = initialEndDate.setHours(23,59,59,0);

const SensorChart = () => {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [rawData, setRawData] = useState([]);
    const [endDate, setEndDate] = useState(initialEndDate);
    const [startDate, setStartDate] = useState(initialStartDate);

    const getUrl = () => {
        return `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_PROJECT}/items/sensor_data?access_token=${process.env.REACT_APP_API_TOKEN}${startDate && endDate ? '&filter[created_on][between]=' + moment(startDate - 60 * 60 * 1000).format() + ',' + moment(endDate - 60 * 60 * 1000).format() : ''}`;

    }

    useEffect(() => {
        fetch(getUrl())
            .then(res => res.json())
            .then(
                (result) => {
                    setIsLoaded(true);
                    setRawData(result);
                },
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            )
    }, [startDate, endDate])

    useLayoutEffect(() => {
        let chart = am4core.create("chartdiv", am4charts.XYChart);

        chart.paddingRight = 20;

        chart.data = rawData.data?.map(data => {
            return {
                date: new Date(data.created_on),
                temperature: data.temperature,
                humidity: data.humidity

            };
        })

        let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
        dateAxis.renderer.grid.template.location = 0;

        let temperatureValueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        temperatureValueAxis.tooltip.disabled = true;
        temperatureValueAxis.renderer.minWidth = 35;
        temperatureValueAxis.title.text = "Temperatur";
        temperatureValueAxis.numberFormatter = new am4core.NumberFormatter();
        temperatureValueAxis.numberFormatter.numberFormat = "#.#' ºC'";

        let humidityValueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        humidityValueAxis.tooltip.disabled = true;
        humidityValueAxis.renderer.minWidth = 35;
        humidityValueAxis.title.text = "Luftfeuchtigkeit";
        humidityValueAxis.renderer.opposite = true;
        humidityValueAxis.numberFormatter = new am4core.NumberFormatter();
        humidityValueAxis.numberFormatter.numberFormat = "#.#' %'";



        let temperatureSeries = chart.series.push(new am4charts.LineSeries());
        temperatureSeries.dataFields.dateX = "date";
        temperatureSeries.dataFields.valueY = "temperature";
        temperatureSeries.tooltipText = "{valueY.value} ºC";
        temperatureSeries.tooltip.getFillFromObject = false;
        temperatureSeries.tooltip.background.fill = am4core.color("#b23c3c");
        temperatureSeries.yAxis = temperatureValueAxis;
        temperatureSeries.strokeWidth = 2;
        temperatureSeries.stroke = am4core.color("#b23c3c");


        let humiditySeries = chart.series.push(new am4charts.LineSeries());
        humiditySeries.dataFields.dateX = "date";
        humiditySeries.dataFields.valueY = "humidity";
        humiditySeries.tooltipText = "{valueY.value} %";
        humiditySeries.yAxis = humidityValueAxis;
        humiditySeries.strokeWidth = 2;

        let scrollbarX = new am4charts.XYChartScrollbar();
        scrollbarX.series.push(temperatureSeries);
        scrollbarX.series.push(humiditySeries);
        chart.scrollbarX = scrollbarX;

        chart.cursor = new am4charts.XYCursor();

        chart.current = chart;

        return () => {
            chart.dispose();
        };
    }, [rawData]);

    const actualSensorData = rawData?.data && rawData.data[rawData.data?.length - 1];

    if (error) {
        return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
        return <div>Loading...</div>;
    } else {
        return (
            <div className="container">
                <div className='header'>
                    <h1 className='title'>Treppenbau.ch Sensor Daten</h1>
                </div>
                <div className="row">
                    <div className="form-group border-dotted">
                        <span className="actual-data">{actualSensorData?.temperature} °C</span>
                        <span className="actual-data">{actualSensorData?.humidity} %</span>
                    </div>
                </div>
                <div className="row">
                    <div className="form-group">
                        <span className="form-label">Von:</span>
                        <DatePicker className="form-element" selected={startDate} onChange={date => setStartDate(date)}
                                    locale="de" dateFormat="dd.MM.yyyy"/>
                    </div>
                    <div className="form-group">
                        <span className="form-label">Bis:</span>
                        <DatePicker className="form-element" selected={endDate} onChange={date => setEndDate(date)}
                                    locale="de" dateFormat="dd.MM.yyyy"/>
                    </div>

                </div>
                <div className="chart-container">
                    <div id="chartdiv" style={{width: "100%", height: "600px"}}/>
                </div>
            </div>
        );
    }
}


export default SensorChart