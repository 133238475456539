import './App.css';
import SensorChart from "./SensorChart";

function App() {
  return (
    <div className="App">
      <SensorChart />
    </div>
  );
}

export default App;
